<template>
  <b-modal
    id="modalCloseMaintenance"
    title="Finalizar mantenimiento"
    hide-footer
    no-close-on-backdrop
    @close="closeModal"
  >
    <validation-observer ref="closeMaintenance">
      <validation-provider name="Estado" #default="{ errors }" rules="required">
        <b-form-group label="Estado">
          <b-form-radio-group
            id="status"
            v-model="status"
            name="label"
            value="alias"
            :options="statusOptions"
          />
        </b-form-group>

        <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <b-form-group :label="'Reporte final'" label-for="documentos">
        <ImageDropzone ref="documents" :files="final_report" max-files="1" />
      </b-form-group>
      <b-button @click="closeMaintenance" variant="primary" class="my-2 float-right">
        Finalizar
      </b-button>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import generalApi from "@/api/general-api";

export default {
  name: "ModalCloseMaintenance",
  components: {
    ImageDropzone,
  },
  data() {
    return {
      final_report: [],
      status: "",
      statusOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      maintenance: 'maintenances/getMaintenance',
    }),
  },
  methods: {
    ...mapActions({
      close: "maintenances/close",
    }),
    async closeMaintenance() {

      const validate = await this.$refs.closeMaintenance.validate();
      if (!validate) return;

      const formData = this.$refs.documents.getFormData("final_report");

      formData.append("status", this.status);

      await this.close({
        id: this.maintenance.id,
        params: formData,
      });
      
      this.$root.$emit('bv::hide::modal', 'modalCloseMaintenance')
      this.resetValues();
    },
    closeModal () {
      this.resetValues();
    },
    resetValues () {
      this.status = ""
      this.final_report = []
    }
  },
  async mounted() {
    const selectStatus = await generalApi.status("maintenance");

    this.statusOptions = selectStatus.data
      .filter(({ alias }) => ["complete", "unfavorable"].includes(alias))
      .map((status) => {
        return { text: status.name, value: status.alias };
      });
  },
};
</script>
